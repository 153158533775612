import {
  userTypeAll,
  userTypeNotSysAdmin,
  userTypeSysAdmin,
} from '../types/Auth'
import { TMenu } from '../types/Menu'
import { TTenantSettings } from '../types/Tenant'

export const MENU_MAIN: TMenu[] = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: { path: '/assets/icons/menu/dash/dash', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
    isVersionBeta: false,
  },
  {
    name: 'Produtos',
    to: '/products',
    icon: { path: '/assets/icons/menu/product/product', extension: '.svg' },
    permissions: userTypeAll,
  },
  {
    name: 'Afiliados',
    to: '/affiliateds',
    icon: { path: '/assets/icons/menu/user/user', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Regras de Comissões',
    to: '/commissionrules',
    icon: {
      path: '/assets/icons/menu/commission/commission',
      extension: '.svg',
    },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Apps',
    to: '/apps',
    icon: { path: '/assets/icons/menu/app/app', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Funil de Vendas',
    to: '/sales/funnel',
    icon: { path: '/assets/icons/menu/funnel/funnel', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Marketplace',
    to: '/marketplace',
    icon: {
      path: '/assets/icons/menu/marketplace/marketplace',
      extension: '.svg',
    },
    permissions: userTypeNotSysAdmin,
  },
  {
    name: 'Indicações',
    to: '/indicated',
    icon: { path: '/assets/icons/menu/indicated/indicated', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
    isValidBySettings: (settings: TTenantSettings | null) => {
      return settings && !settings.disableIndicatedComissions
    },
  },
]

export const MENU_REPORT: TMenu[] = [
  {
    name: 'Transações',
    to: '/checkout/order',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeAll,
  },
  {
    name: 'Assinaturas',
    to: '/checkout/subscription',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutSubscription',
      extension: '.svg',
    },
    permissions: userTypeAll,
    isVersionBeta: true,
  },
  {
    name: 'Análise Vendas',
    to: '/checkout/analytics',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Acessos Checkout',
    to: '/checkout/access',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Taxa de Antecipação',
    to: '/checkout/anticipations',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Registro de Ocorrências',
    to: '/admin/occurrences',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Análise por produtor',
    to: '/admin/sales/analysis',
    icon: {
      path: '/assets/icons/menu/checkout/checkoutOrder',
      extension: '.svg',
    },
    permissions: userTypeSysAdmin,
  },
  {
    name: 'Webhooks Enviados',
    to: '/webhooks/sends',
    icon: { path: '/assets/icons/menu/webhook/webhook', extension: '.svg' },
    permissions: userTypeNotSysAdmin,
  },
]

export const MENU_CONFIG = [
  {
    name: 'Configurações',
    to: '/settings',
    icon: {
      path: '/assets/icons/menu/settingsIcon/settingIcon',
      extension: '.svg',
    },
    permissions: userTypeAll,
  },
]

export const MENU_SYSTEM = [
  {
    name: 'Usuários',
    to: '/settings/users',
    icon: { path: '/assets/icons/menu/user/user', extension: '.svg' },
    permissions: userTypeSysAdmin,
  },
]
