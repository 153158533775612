import React, { useCallback, useContext } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { useFieldArray } from 'react-hook-form'
import {
  TPaymentMethodEnum,
  TProductFrequency,
  TProductPrice,
  TProductPriceInstallmentFees,
  TProductPricePayment,
  TProductTypePayment,
} from '../../../../core/types/Product'
import { IMultiSelectedValueItem } from '../../../../components/Dialogs/MultiSelectedValueDialog'
import { IPayment } from '../../../../core/types/Payment'
import PaymentController from '../../../../core/controllers/PaymentController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import MultiSelectedDialog, {
  MultiSelectedItem,
} from '../../../../components/Dialogs/MultiSelectedDialog'
import OrderBumpIcon from '@mui/icons-material/Discount'
import { IOrderBump } from '../../../../core/types/OrderBump'
import Hosts, { payUrl } from '../../../../core/functions/hosts'
import CopiedSnackbar from '../../../../components/Snackbar/CopiedSnackbar'
import PricesDetails from './PricesDetails'
import ProductFunctions from '../../../../core/functions/ProductFunctions'
import ProductPriceDetail from './ProductPrice/ProductPriceDetail'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import ProductPriceDisable from './ProductPrice/ProductPriceDisable'
import ProductController from '../../../../core/controllers/ProductController'
import { red } from '@mui/material/colors'
import ContentDialog from '../../../../components/Dialogs/ContentDialog'
import RowRadioButtonsGroup from '../../../../components/RadioGroup/RowRadioButtonsGroup'
import UtilFunctions from '../../../../core/functions/UtilFunctions'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import SimpleCard from '../../../../components/Cards/SimpleCard'
import ProductPriceList from './ProductPrice/ProductPriceList'
import { AuthContext } from '../../../../core/context/AuthContext'
import { TUserType } from '../../../../core/types/Auth'
import { ProductPricesProps } from './ProductPrice/ProductPriceTypes'
import { productPriceDefault } from './ProductPrice/ProductPriceConsts'
import ProductPriceFunctions from './ProductPrice/ProductPriceFunctions'

const ProductPrices = ({
  control,
  productOrderBumps,
  tenantBank,
  watch,
  addDelete,
  setProductOrderBumps,
  setError,
  execute,
  setValue,
}: ProductPricesProps): JSX.Element => {
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: `prices`,
  })

  const [open, setOpen] = React.useState(false)
  const [openConfirmDelete, setOpenConfirmeDelete] = React.useState(false)
  const [openOrderBumps, setOpenOrderBumps] = React.useState(false)
  const [openDisable, setOpenDisable] = React.useState(false)
  const [copied, setCopied] = React.useState(false)
  const [copiedLink, setCopiedLink] = React.useState(false)
  const [openCode, setOpenCode] = React.useState(false)
  const [copiedCode, setCopiedCode] = React.useState(false)
  const [openErrorDisablePrices, setOpenErrorDisablePrices] =
    React.useState(false)
  const [openDeleteDisabled, setOpenDeleteDisabled] = React.useState(false)
  const [optionDelete, setOptionDelete] = React.useState(false)

  const [newRedirectProductPriceId, setNewRedirectProductPriceId] =
    React.useState('')
  const [errorReplaceRedirect, setErrorReplaceRedirect] = React.useState('')

  const [payments, setPayments] = React.useState<IPayment[]>([])
  const [methodTypes, setMethodTypes] = React.useState<
    IMultiSelectedValueItem[]
  >([])
  const [options, setOptions] = React.useState<number[]>([])

  const [current, setCurrent] = React.useState<TProductPrice>({
    ...productPriceDefault,
  })

  const productPriceIndex = React.useRef<number>(-1)
  const checkoutUrl = React.useRef<string>('')
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin
  const typePaymentValue = watch('typePayment')

  const [loading, setLoading] = React.useState(false)

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => Hosts.openNewBlank(checkoutUrl.current)}
        sx={{ color: 'white' }}
      >
        ABRIR LINK
      </Button>
    </React.Fragment>
  )

  const getMaxInstallments = () => Math.max(...options)

  const getPaymentSelected = (p: IPayment): IMultiSelectedValueItem => {
    const filtered = current.paymentMethods.find((pm) => pm.methodType === p.id)
    const selected = filtered !== undefined
    let value = 1
    if (p.id === TPaymentMethodEnum.CreditCard) {
      value = selected ? filtered.installmentsNumber : getMaxInstallments()
    }
    return {
      id: p.id.toString(),
      name: p.name,
      selected,
      value,
      hasValue: p.hasInstallments,
    }
  }

  const proccessPayment = (p: IPayment): IMultiSelectedValueItem => {
    if (typePaymentValue === TProductTypePayment.RecurringSubscription) {
      if (
        [TPaymentMethodEnum.CreditCard, TPaymentMethodEnum.Pix].includes(p.id)
      ) {
        return getPaymentSelected(p)
      } else if (TProductFrequency.Yearly === current.frequency) {
        return getPaymentSelected(p)
      }
    }
    return getPaymentSelected(p)
  }

  const handlePayments = async () => {
    const value = current.value

    if (value === undefined || value <= 0) {
      //setError('É necessário informar o valor do preço primeiro')
      setMethodTypes([])
      return
    }

    if (value < 5) {
      //setError('O preço do produto não pode ser menor que R$ 5,00')
      setMethodTypes([])
      return
    }

    if (
      typePaymentValue === TProductTypePayment.RecurringSubscription &&
      !current.frequency
    ) {
      //setError('É necessário selecionar uma frequência')
      setMethodTypes([])
      return
    }

    var pays: IPayment[] = []

    if (payments.length <= 0) {
      const data = await PaymentController.getAll()
      const error = data as IErrorResponse
      if (error.code) {
        return
      }
      pays = data as IPayment[]
      setPayments(pays)
    } else {
      pays = payments
    }

    const newList = pays.map((p) => proccessPayment(p))

    const frequencyValue = current.frequency
    if (typePaymentValue === TProductTypePayment.RecurringSubscription) {
      if (!frequencyValue) {
        setError('É necessário informar a frequência')
        return
      }
    }

    const total = ProductFunctions.maxPaymentInstallment(
      typePaymentValue,
      frequencyValue,
      value
    )

    const op: number[] = []
    for (var i = 1; i <= total; i++) {
      op.push(i)
    }

    setOptions(op)
    setMethodTypes(newList)
    //setOpenPayments(true)
  }

  const closePayments = () => {
    //setOpenPayments(false)
  }

  const handleSavePayments = (
    data: IMultiSelectedValueItem[],
    productPrice: TProductPrice
  ) => {
    const paymentMethods =
      watch(`prices.${productPriceIndex.current}.paymentMethods`) || []
    const newPayments: TProductPricePayment[] = []

    const notSelecteds = data.filter((d) => d.selected === false)
    paymentMethods.map((payment) => {
      const exists = notSelecteds.find(
        (ns) => Number(ns.id) === payment.methodType
      )
      if (!exists) {
        newPayments.push({ ...payment })
      }
      return true
    })

    data.map((d) => {
      if (d.selected) {
        const filtered = newPayments.find(
          (payment) => payment.methodType === Number(d.id)
        )
        if (!filtered) {
          newPayments.push({
            methodType: Number(d.id),
            description: d.name,
            installmentsNumber: d.value,
            installments: [],
          })
        } else {
          filtered!.installmentsNumber = d.value
        }
      }
      return true
    })

    setCurrent({ ...productPrice, paymentMethods: newPayments })
  }

  const handleOpenPrice = (price?: TProductPrice, index?: number) => {
    productPriceIndex.current = index ?? -1
    const payMethods: TProductPricePayment[] = []

    if (typePaymentValue === TProductTypePayment.RecurringSubscription) {
      payMethods.push({
        installmentsNumber: 1,
        methodType: TPaymentMethodEnum.CreditCard,
        installments: [],
      })
    }

    if (price) {
      price.id = watch(`prices.${productPriceIndex.current}.id`)
    }

    const newPrice = price ?? {
      id: '',
      value: undefined,
      paymentMethods: payMethods,
      orderBumps: [],
      hideCheckoutAddress: false,
      disabled: false,
      disableDate: null,
      redirectProductPriceId: null,
      isDisabled: false,
      installmentFeesType: TProductPriceInstallmentFees.Customer,
    }
    setCurrent({ ...newPrice })
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent({ ...productPriceDefault })
    setOpen(false)
  }

  const handleOpenDisable = (price: TProductPrice, index: number) => {
    productPriceIndex.current = index
    const id = watch(`prices.${index}.id`)
    if (price.isDisabled) {
      handleEnable()
    } else {
      const prices = fields.map((f, i) => ({
        ...f,
        id: watch(`prices.${i}.id`),
      }))
      if (
        prices.filter((p) => !p.isDisabled && !p.disableDate && p.id !== id)
          .length <= 0
      ) {
        setOpenErrorDisablePrices(true)
        return
      }
      setCurrent({ ...price, id, disabled: !price.disableDate })
      setOpenDisable(true)
    }
  }

  const handleDelete = (price: TProductPrice, index: number) => {
    productPriceIndex.current = index
    const id = watch(`prices.${index}.id`)
    const list = fields.filter((f) => f.redirectProductPriceId === id)
    if (list.length > 0) {
      setOptionDelete(false)
      setOpenDeleteDisabled(true)
      setNewRedirectProductPriceId('')
      return
    }

    setCurrent({ ...price })
    setOpenConfirmeDelete(true)
  }

  const closeDelete = () => {
    setCurrent({ ...productPriceDefault })
    productPriceIndex.current = -1
    setOpenConfirmeDelete(false)
  }

  const deletePrice = () => {
    if (current.id !== '') {
      const id = watch(`prices.${productPriceIndex.current}.id`)
      addDelete(id)
    }
    remove(productPriceIndex.current)
  }

  const handleReplaceRedirectPrice = async () => {
    if (optionDelete) {
      if (newRedirectProductPriceId === '') {
        setErrorReplaceRedirect('Campo obrigatório')
        return false
      }

      const id = watch(`prices.${productPriceIndex.current}.id`)
      for (var i = 0; i < fields.length; i++) {
        const priceId = watch(`prices.${i}.id`)
        if (fields[i].redirectProductPriceId === id) {
          let response = await ProductController.disablePrice({
            priceId,
            disableDate: fields[i].disableDate,
            redirectProductPriceId: newRedirectProductPriceId,
          })

          if (!response.success) {
            setError(response.error)
            return false
          }
        }
      }

      await ProductController.deletePrice({ priceId: id })
      setCurrent({ ...productPriceDefault })
      setNewRedirectProductPriceId('')
      execute()
    }
    return true
  }

  const handleOrderBumps = () => {
    var newList: MultiSelectedItem[] = []
    productOrderBumps.map((productOrderBump) => {
      const exists = current.orderBumps?.filter(
        (ob) => ob.orderBumpId === productOrderBump.id
      )
      newList.push({
        id: productOrderBump.id,
        name: productOrderBump.name,
        selected: exists !== undefined && exists.length > 0,
        object: productOrderBump.object,
        order:
          exists !== undefined && exists.length > 0
            ? exists[0].order
            : undefined,
      })
      return true
    })

    newList = newList.sort((a, b) => (a.order || 0) - (b.order || 0))
    newList = newList.sort((a, b) =>
      (a.order || 0) === 0 || (b.order || 0) === 0 ? -1 : 0
    )

    setProductOrderBumps(newList)
    setOpenOrderBumps(true)
  }

  const closeOrderBumps = () => {
    setOpenOrderBumps(false)
  }

  const handleSaveOrderBumps = (data: MultiSelectedItem[]) => {
    if (!current.orderBumps || current.orderBumps === null) {
      current.orderBumps = []
    }

    const auxOrderBumps = [...current.orderBumps]
    auxOrderBumps.map((aux) => {
      const datas = data.filter((d) => d.id === aux.orderBumpId && !d.selected)
      if (datas.length > 0) {
        current.orderBumps = current.orderBumps!.filter(
          (ob) => ob.orderBumpId !== aux.orderBumpId
        )
      }
      return true
    })

    data.map((d) => {
      if (d.selected) {
        const filtered = current.orderBumps!.filter(
          (orderBump) => orderBump.orderBumpId === d.id
        )
        const list = productOrderBumps.filter(
          (orderBump) => orderBump.object.id === d.id
        )
        const positioned = list[0].object as IOrderBump
        if (filtered.length <= 0) {
          current.orderBumps!.push({
            id: d.id,
            orderBumpId: d.id,
            order: d.order,
            productPrice: {
              id: positioned.productPrice?.id || '',
              value: positioned.productPrice?.value,
              product: {
                id: positioned.productPrice?.product?.id || '',
                name: positioned.productPrice?.product?.name || '',
              },
            },
          })
        } else {
          filtered[0].order = d.order
        }
      }
      return true
    })

    setCurrent({ ...current })
  }

  const handleChangeCopyURL = async (producerLink: boolean, index?: number) => {
    if (index !== undefined) {
      checkoutUrl.current = `${payUrl}${producerLink ? '/pro' : ''}/${watch(`prices.${index}.codeId`)}`
    } else {
      checkoutUrl.current = `${payUrl}${producerLink ? '/pro' : ''}/${current.codeId}`
    }

    await Hosts.copyTextToClipboard(checkoutUrl.current)
    setCopiedLink(true)
  }

  const handleChangeCode = async (index: number) => {
    productPriceIndex.current = index
    setOpenCode(true)
  }

  const handleCloseCode = () => {
    setCurrent({ ...productPriceDefault })
    productPriceIndex.current = -1
    setOpenCode(false)
  }

  const handleSavePrice = useCallback(() => {
    if (!current.value || current.value < 5) {
      setError(
        `O preço do produto precisa ser no mínimo de ${NumberFunctions.formatMoneyDefault(5)}`
      )
      return
    }
    if (
      current.paymentMethods === undefined ||
      current.paymentMethods.length <= 0
    ) {
      setError(
        `O preço ${NumberFunctions.formatMoneyDefault(
          current.value
        )} do produto precisa ter pelo menos uma forma de pagamento`
      )
      return
    }
    if (typePaymentValue === TProductTypePayment.RecurringSubscription) {
      if (!current.frequency || current.frequency === null) {
        setError(
          `O preço ${NumberFunctions.formatMoneyDefault(
            current.value
          )} do produto precisa selecionar uma frequência de cobrança`
        )
        return
      }

      if (current.frequency !== TProductFrequency.Yearly) {
        current.paymentMethods = current.paymentMethods.filter((pm) =>
          [TPaymentMethodEnum.CreditCard, TPaymentMethodEnum.Pix].includes(
            pm.methodType
          )
        )
      }
    }

    const paymentCreditCard = current.paymentMethods.find(
      (p) => p.methodType === TPaymentMethodEnum.CreditCard
    )
    if (paymentCreditCard) {
      const total = ProductFunctions.maxPaymentInstallment(
        typePaymentValue,
        current.frequency,
        current.value
      )

      if (paymentCreditCard.installmentsNumber > total) {
        paymentCreditCard.installmentsNumber = total
      }

      if (current.value < 10) {
        current.paymentMethods = current.paymentMethods.filter(
          (pm) =>
            ![
              TPaymentMethodEnum.TwoCreditCard,
              TPaymentMethodEnum.CreditCardAndPix,
            ].includes(pm.methodType)
        )
      }
    }

    if (productPriceIndex.current < 0) {
      append({ ...current })
    } else {
      current.id = watch(`prices.${productPriceIndex.current}.id`)
      update(productPriceIndex.current, { ...current })
    }
    setOpen(false)
    setCurrent({ ...productPriceDefault })
    handleChangeOffer()
  }, [current, setCurrent, watch])

  const handleDisable = async () => {
    current.id = watch(`prices.${productPriceIndex.current}.id`)
    setLoading(true)
    try {
      if (current.id !== '') {
        let response = await ProductController.disablePrice({
          priceId: current.id,
          disableDate: current.disableDate,
          redirectProductPriceId: current.redirectProductPriceId,
        })

        if (!response.success) {
          setError(response.error)
          return
        }

        setOpenDisable(false)
        setCurrent({ ...productPriceDefault })
        execute()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleEnable = async () => {
    current.id = watch(`prices.${productPriceIndex.current}.id`)
    setLoading(true)
    try {
      if (current.id !== '') {
        let response = await ProductController.enablePrice({
          priceId: current.id,
        })

        if (!response.success) {
          setError(response.error)
          return
        }

        setOpenDisable(false)
        setCurrent({ ...productPriceDefault })
        execute()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleChangeOffer = useCallback(async () => {
    const productId = watch('id')
    if (!productId) {
      return
    }
    const prices = watch('prices')
    setLoading(true)
    try {
      if (prices) {
        await Promise.all(
          prices.map(async (price: any) => {
            price.productId = productId
            let response
            if (price.id === '') {
              response = await ProductController.insertPrice({ data: price })
            } else {
              response = await ProductController.updatePrice({ data: price })
            }
            if (!response.success) {
              setError(response.error)
              return
            }
          })
        )
        const dataPrices = await ProductController.getPricesByProduct({
          productId,
        })
        if (dataPrices) {
          setValue('prices', dataPrices as TProductPrice[])
        }
      }
    } finally {
      setLoading(false)
    }
  }, [watch, setLoading, setValue])

  React.useEffect(() => {
    handlePayments()
  }, [current])

  return (
    <SimpleCard sx={{ p: '32px' }}>
      <Box>
        <Stack spacing={1}>
          {!ProductPriceFunctions.isTenantBank(tenantBank) && (
            <Alert severity="warning">
              <AlertTitle>Dados bancário não fornecido</AlertTitle>
              Para visualizar os links do checkout é necessário informar uma
              conta bancária em <i>Menu - Configurações - Dados Bancário</i>
            </Alert>
          )}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              color="#343948"
              fontWeight={700}
              fontSize="16px"
              lineHeight="20px"
            >
              Minhas Ofertas
            </Typography>

            {!isAdmin && (
              <Button
                variant="outlined"
                size="large"
                sx={{ textTransform: 'none' }}
                onClick={() => handleOpenPrice()}
              >
                <Typography fontWeight={700} fontSize="14px" lineHeight="24px">
                  + Nova oferta
                </Typography>
              </Button>
            )}
          </Stack>
          <ProductPriceList
            isTenantBank={ProductPriceFunctions.isTenantBank(tenantBank)}
            fields={fields}
            watch={watch}
            onCopyURL={handleChangeCopyURL}
            onOpenPrice={handleOpenPrice}
            onOpenDisable={handleOpenDisable}
            onDelete={handleDelete}
          />
        </Stack>

        <ProductPriceDetail
          open={open}
          sending={false}
          price={current}
          offerName={current}
          buttonPositiveTitle={watch('id') ? 'Salvar' : 'Continuar'}
          typePaymentValue={
            typePaymentValue ?? TProductTypePayment.OneTimePayment
          }
          isTenantBank={ProductPriceFunctions.isTenantBank(tenantBank)}
          setOpen={setOpen}
          handleClose={handleClose}
          setPrice={setCurrent}
          onSave={handleSavePrice}
          onCopyURL={handleChangeCopyURL}
          onPayments={handlePayments}
          onOrderBump={handleOrderBumps}
          setCopied={setCopied}
          methodTypes={methodTypes}
          setMethodTypes={setMethodTypes}
          onSavePayments={handleSavePayments}
          options={options}
        />

        <ProductPriceDisable
          open={openDisable}
          sending={false}
          price={current}
          prices={fields.map((f, i) => ({ ...f, id: watch(`prices.${i}.id`) }))}
          setOpen={setOpenDisable}
          handleClose={() => setOpenDisable(false)}
          setPrice={setCurrent}
          onSave={handleDisable}
        />

        {/*<MultiSelectedValueDialog
          open={openPayments}
          disableSelected={
            typePaymentValue === TProductTypePayment.RecurringSubscription
          }
          list={methodTypes}
          setList={setMethodTypes}
          onClose={closePayments}
          onSave={handleSavePayments}
          setError={setError}
          options={options}
        />*/}

        <MultiSelectedDialog
          open={openOrderBumps}
          title="Selecione os Order Bumps"
          description="Pressione e arraste um item selecionado para alterar a order"
          icon={<OrderBumpIcon />}
          list={productOrderBumps}
          setList={setProductOrderBumps}
          onClose={closeOrderBumps}
          onSave={handleSaveOrderBumps}
        />

        <ConfirmDialog
          open={openConfirmDelete}
          title="Confirmar exclusão"
          message="Deseja realmente deletar a oferta?"
          onClose={closeDelete}
          onYes={deletePrice}
        />

        <CopiedSnackbar
          open={copiedLink}
          onClose={() => setCopiedLink(false)}
          action={action}
        />

        <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />

        <CopiedSnackbar
          open={copiedCode}
          onClose={() => setCopiedCode(false)}
        />

        <PricesDetails
          open={openCode}
          setOpen={setOpenCode}
          handleCloseItem={handleCloseCode}
          codeId={current?.codeId || ''}
          setCopied={setCopiedCode}
        />

        <ContentDialog
          open={openErrorDisablePrices}
          onClose={() => setOpenErrorDisablePrices(false)}
          hasCancel={false}
          title="Impossível prosseguir"
        >
          <Typography>
            Não existem mais opções para o redirecionamento da oferta.
          </Typography>
          <Typography>
            Crie uma nova oferta ou habilite alguma outra oferta para
            desabilitar essa oferta.
          </Typography>
        </ContentDialog>

        <ContentDialog
          open={openDeleteDisabled}
          onClose={() => setOpenDeleteDisabled(false)}
          hasCancel={false}
          title="Impossível prosseguir"
          labelPrositive={optionDelete ? 'Substituir e deletar' : 'Cancelar'}
          onPositive={handleReplaceRedirectPrice}
        >
          <Typography>
            Essa oferta está vinculada como o direcionamento de uma oferta
            desabilitada ou que será desabilitada em breve.
          </Typography>
          <Alert severity="warning" sx={{ width: '100%', my: 2 }}>
            Só é permitido a exclusão de uma oferta que não dependam dela.
          </Alert>
          <Typography>
            Deseja substituir o redirecionamento que está para essa oferta por
            uma outra oferta ativa?
          </Typography>
          <RowRadioButtonsGroup
            value={optionDelete}
            onChange={(value) => {
              if (typeof value === 'string') {
                value = UtilFunctions.booleanify(value)
              }
              setOptionDelete(value)
            }}
            items={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
          />
          <Collapse in={optionDelete}>
            <Box>
              <Typography>
                Selecione uma outra oferta ativa para substituir essa que será
                deletada
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                size="small"
                value={newRedirectProductPriceId}
                onChange={(e) => setNewRedirectProductPriceId(e.target.value)}
                error={errorReplaceRedirect !== ''}
              >
                {fields
                  .map((f, i) => ({ ...f, id: watch(`prices.${i}.id`) }))
                  .filter(
                    (p, i) =>
                      !p.isDisabled &&
                      !p.disableDate &&
                      i !== productPriceIndex.current
                  )
                  .map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {NumberFunctions.formatMoneyDefault(p.value)}
                    </MenuItem>
                  ))}
              </Select>
              {errorReplaceRedirect !== '' && (
                <Typography variant="overline" color={red[700]}>
                  {errorReplaceRedirect}
                </Typography>
              )}
            </Box>
          </Collapse>
        </ContentDialog>
        <LoadingBackdrop open={loading} />
      </Box>
    </SimpleCard>
  )
}

export default ProductPrices
