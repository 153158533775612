import { IPayment } from '../types/Payment'
import { TPaymentMethodEnum } from '../types/Product'

export const methodTypes: { data: IPayment[] } = {
  data: [
    {
      id: TPaymentMethodEnum.CreditCard,
      code: 'CreditCard',
      name: 'Cartão de Crédito',
      hasInstallments: true,
    },
    // {
    //     id: methodTypeDebitCard,
    //     code: 'DebitCard',
    //     name: 'Cartão de Débito',
    //     hasInstallments: false,
    // },
    {
      id: TPaymentMethodEnum.Pix,
      code: 'Pix',
      name: 'Pix',
      hasInstallments: false,
    },
    {
      id: TPaymentMethodEnum.Boleto,
      code: 'Boleto',
      name: 'Boleto',
      hasInstallments: false,
    },
    {
      id: TPaymentMethodEnum.TwoCreditCard,
      code: 'TwoCreditCard',
      name: '2 Cartões',
      hasInstallments: false,
    },
    {
      id: TPaymentMethodEnum.CreditCardAndPix,
      code: 'CreditCardAndPix',
      name: 'Cartão e Pix',
      hasInstallments: false,
    },
  ],
}
