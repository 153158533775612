import PaymentIcon from '@mui/icons-material/Paid'
import { TPaymentMethodEnum } from '../../../core/types/Product'

const MethodTypeSmallIcon = ({
  methodType,
  widthCustom,
  heightCustom,
}: {
  methodType?: TPaymentMethodEnum | string
  widthCustom?: string
  heightCustom?: string
}) => {
  switch (methodType) {
    case 'credit_card':
    case 'two_credit_card':
    case 'credit_card_pix':
    case TPaymentMethodEnum.CreditCard:
    case TPaymentMethodEnum.TwoCreditCard:
    case TPaymentMethodEnum.CreditCardAndPix:
      return (
        <img
          src="/assets/icons/payments/pay.svg"
          alt="credit-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
    case 'debit_card':
    case TPaymentMethodEnum.DebitCard:
      return (
        <img
          src="/assets/icons/payments/pay.svg"
          alt="debit-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
    case 'pix':
    case TPaymentMethodEnum.Pix:
      return (
        <img
          src="/assets/icons/payments/pix.svg"
          alt="pix-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
    case 'boleto':
    case TPaymentMethodEnum.Boleto:
      return (
        <img
          src="/assets/icons/payments/barcode.svg"
          alt="invoice-payment-sell"
          style={{ width: widthCustom, height: heightCustom }}
        />
      )
  }

  return <PaymentIcon fontSize="small" sx={{ color: '#1C52BD' }} />
}

export default MethodTypeSmallIcon
