import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import InputSearch from '../../components/Inputs/InputSearch'
import { useCommissionRules } from '../../core/hooks/useCommissionRules'
import React from 'react'
import {
  TCommissionRule,
  TCommissionRuleType,
} from '../../core/types/CommissionRule'
import CommissionRuleDetails from './CommissionRule'
import NumberFunctions from '../../core/functions/NumberFunctions'
import CommissionRulesController from '../../core/controllers/CommissionRuleController'
import LoadingBackdrop from '../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../components/Snackbar/SuccessSnackbar'
import { useProductAffiliations } from '../../core/hooks/useProductAffiliations'
import DataTable, { IDataTableColumn } from '../../components/Tables/DataTable'
import { IconDelete, IconEdit } from '../../core/constants/IconConsts'
import { dateString } from '../../core/functions/dateTime'
import Convertions from '../../core/functions/convertions'
import TitlePageTypography from '../../components/Typographys/TitlePageTypography'
import SelectInput from '../../components/SelectInput/SelectInput'
import { ItensOptions } from '../../components/Tables/constans'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import CommissionRuleItemMobile from './CommissionRule/CommissionRuleItemMobile'

const commisionRuleDefault: TCommissionRule = {
  id: '',
  codeId: '',
  identifier: '',
  commissionPercentage: 0,
  globalCommissionPercentage: 0,
  deadline: 0,
  ruleMeta: 0,
  ruleType: TCommissionRuleType.Sales,
  createdDate: new Date(),
  closingDate: null,
  restartCommission: false,
}

const CommissionRulesPage = () => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')

  const [open, setOpen] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [current, setCurrent] =
    React.useState<TCommissionRule>(commisionRuleDefault)

  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')

  const oldSearch = React.useRef('')
  const removeId = React.useRef('')

  const { data: products, loading: loadingProducts } = useProductAffiliations()
  const { loading, data, error, execute } = useCommissionRules({
    page,
    rows: rowsPerPage,
    search,
  })

  const columns: readonly IDataTableColumn<TCommissionRule>[] = [
    {
      id: 'createdDate',
      label: 'Data do início',
      minWidth: 80,
      format: (value) => dateString(value),
    },
    {
      id: 'closingDate',
      label: 'Data do fim',
      minWidth: 80,
      format: (value) => (value ? dateString(value) : 'Eterno'),
    },
    {
      id: 'identifier',
      label: 'Identificação',
      minWidth: 100,
    },
    {
      id: 'ruleType',
      label: 'Tipo',
      minWidth: 80,
      format: (value) => Convertions.commissionRuleTypeToString(value),
    },
    {
      id: 'commissionPercentage',
      label: 'Comissão',
      minWidth: 70,
      align: 'right',
      format: (value) => NumberFunctions.toPercentage(value),
    },
    {
      id: 'globalCommissionPercentage',
      label: 'Comissão Global',
      minWidth: 70,
      align: 'right',
      format: (value) => NumberFunctions.toPercentage(value),
    },
    {
      id: 'action',
      label: 'Ações',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Editar regra de comissão">
              <IconButton size="small" onClick={() => handleOpen(row)}>
                <img src={IconEdit} alt="Editar" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir regra de comissão">
              <IconButton
                size="small"
                onClick={() => handleChangeOpenDelete(row.id)}
                color="error"
              >
                <img src={IconDelete} alt="Delete" />
              </IconButton>
            </Tooltip>
          </Stack>
        )
      },
    },
  ]

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleOpen = (commission: TCommissionRule) => {
    setCurrent({ ...commission })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCurrent(commisionRuleDefault)
  }

  const saveCommissionRule = async () => {
    if (!current) {
      setErrorSending('Crie ou selecione uma regra de comissão')
      return
    }

    setSending(true)
    setSuccess(false)
    setErrorSending('')
    try {
      const isNew = current.codeId === ''
      var response

      if (isNew) {
        response = await CommissionRulesController.insert({ data: current })
      } else {
        response = await CommissionRulesController.update({ data: current })
      }

      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setSuccess(true)

        if (isNew) {
          setCurrent({
            ...current,
            id: response.data.id,
            codeId: response.data.codeId,
          })
        } else {
          handleClose()
        }
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  const saveCommissionRuleProducts = async (
    productAffiliationIds: string[]
  ) => {
    if (!current) {
      setErrorSending('Crie ou selecione uma regra de comissão')
      return
    }

    setSending(true)
    setSuccess(false)
    setErrorSending('')
    try {
      const response = await CommissionRulesController.insertProducts({
        data: {
          codeId: current.codeId,
          productAffiliationIds,
        },
      })
      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setSuccess(true)
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeOpenDelete = (id: string) => {
    removeId.current = id
    setOpenDelete(true)
  }

  const handleChangeCloseDelete = () => {
    removeId.current = ''
    setOpenDelete(false)
  }

  const removeCommissionRule = async () => {
    if (removeId.current === '') {
      setErrorSending('Crie ou selecione uma regra de comissão')
      return
    }
    setSending(true)
    setSuccess(false)
    setErrorSending('')
    try {
      var response = await CommissionRulesController.remove({
        id: removeId.current,
      })
      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setSuccess(true)
        handleClose()
        execute()
      }
    } finally {
      removeId.current = ''
      setSending(false)
    }
  }

  const renderItemMobile = (item: TCommissionRule, index: number) => {
    return (
      <CommissionRuleItemMobile item={item} index={index} onEdit={handleOpen} />
    )
  }

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        alignItems={{ xs: 'start', sm: 'center' }}
        justifyContent="space-between"
        width="100%"
        sx={{ p: 2 }}
      >
        <TitlePageTypography>Regras de Comissões</TitlePageTypography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(commisionRuleDefault)}
          sx={{
            borderRadius: '4px',
            padding: '8px 16px',
            gap: '10px',
            textTransform: 'none',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: 700,
            }}
          >
            Nova Regra
          </Typography>
        </Button>
      </Stack>

      <Stack spacing={2}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'start', sm: 'center' }}
          justifyContent="space-between"
          spacing={1}
          sx={{ p: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <InputLabel id="itens-total">
              {data.total} regras encontradas
            </InputLabel>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <SelectInput
              value={rowsPerPage}
              onChange={(event) =>
                handleChangeRowsPerPage(
                  NumberFunctions.toNumber(event.target.value)
                )
              }
              options={ItensOptions}
              minWidth={107}
              maxWidth="50%"
            />

            <InputSearch
              id="comission-rules-search"
              key="comission-rules-search"
              name="Pesquisar regra de comissão"
              size="small"
              setSearch={setSearch}
              execute={execute}
              adornmentToStart
              removeLabel
              sx={{ flexGrow: 1 }}
            />
          </Stack>
        </Stack>

        <DataTable
          columns={columns}
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          renderItemMobile={renderItemMobile}
        />
      </Stack>

      <CommissionRuleDetails
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        setCommissionRule={setCurrent}
        saveCommissionRule={saveCommissionRule}
        saveCommissionRuleProducts={saveCommissionRuleProducts}
        commission={current}
        products={products}
      />

      <ConfirmDialog
        open={openDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o campo personalizado?"
        onClose={handleChangeCloseDelete}
        onYes={removeCommissionRule}
      />

      <LoadingBackdrop open={loading || sending || loadingProducts} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar
        open={success}
        autoHideDuration={2000}
        onClose={() => setSuccess(false)}
      >
        Concluído com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default CommissionRulesPage
