import {
  Box,
  Button,
  Divider,
  Fade,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { red } from '@mui/material/colors'
import {
  TCommissionRule,
  TCommissionRuleType,
  commissionRuleTypes,
} from '../../../../core/types/CommissionRule'
import ListItemDetail from '../../../../components/ListItem/ListItemDetail'
import Convertions from '../../../../core/functions/convertions'
import { maskPercentage } from '../../../../masks/masks'
import SwitchRound from '../../../../components/Switch/SwitchRound'
import BasicDatePicker from '../../../../components/DateTime/BasicDatePicker'
import { Info } from '@mui/icons-material'
import InfoTooltip from '../../../../components/Tooltip/InfoTooltip'

export interface ICommissionRuleDetailsProps {
  commission: TCommissionRule
  handleClose: () => void
  setCommissionRule: (value: TCommissionRule) => void
  saveCommissionRule: () => void
}

const CommissionRuleData = ({
  commission,
  handleClose,
  setCommissionRule,
  saveCommissionRule,
}: ICommissionRuleDetailsProps) => {
  const [errorIdentifier, setErrorIdentifier] = useState('')

  const handleSave = () => {
    var error = false
    if (commission.identifier.trim() === '') {
      setErrorIdentifier('Campo obrigatório')
      error = true
    }

    if (error) {
      return
    }

    saveCommissionRule()
  }

  return (
    <Stack direction="column" spacing={2} sx={{ py: 1 }}>
      <ListItemDetail
        title="Identificação"
        value={
          <Box>
            <TextField
              fullWidth
              size="small"
              type="text"
              value={commission.identifier}
              onChange={(e) => {
                setCommissionRule({ ...commission, identifier: e.target.value })
                setErrorIdentifier('')
              }}
              error={commission.identifier === '' && errorIdentifier !== ''}
            />
            {commission.identifier === '' && errorIdentifier !== '' && (
              <Typography variant="caption" color={red[700]}>
                {errorIdentifier}
              </Typography>
            )}
          </Box>
        }
        viewDivider={false}
        py={0}
      />
      <ListItemDetail
        title="Tipo"
        value={
          <Select
            fullWidth
            variant="outlined"
            size="small"
            labelId="select-label"
            value={Convertions.commissionRuleTypeToString(commission?.ruleType)}
            onChange={(e) =>
              setCommissionRule({
                ...commission,
                ruleType:
                  e.target.value ===
                  Convertions.commissionRuleTypeToString(
                    TCommissionRuleType.Sales
                  )
                    ? TCommissionRuleType.Sales
                    : TCommissionRuleType.Commissions,
              })
            }
          >
            {commissionRuleTypes.map((option) => (
              <MenuItem value={Convertions.commissionRuleTypeToString(option)}>
                {Convertions.commissionRuleTypeToString(option)}
              </MenuItem>
            ))}
          </Select>
        }
        viewDivider={false}
        py={0}
      />
      <ListItemDetail
        title="Meta"
        value={
          <OutlinedInput
            fullWidth
            size="small"
            type="text"
            value={commission.ruleMeta}
            onChange={(e) =>
              setCommissionRule({
                ...commission,
                ruleMeta: Number(e.target.value),
              })
            }
            startAdornment={
              commission.ruleType === TCommissionRuleType.Commissions
                ? 'R$ '
                : undefined
            }
          />
        }
        viewDivider={false}
        py={0}
      />
      <ListItemDetail
        title="Comissão"
        value={
          <OutlinedInput
            fullWidth
            size="small"
            id="commission-label"
            value={maskPercentage(commission.commissionPercentage)}
            onChange={(e) => {
              const commissionPercentage = maskPercentage(e.target.value)
                .replaceAll('.', '')
                .replaceAll(',', '.')
              setCommissionRule({
                ...commission,
                commissionPercentage: Number(commissionPercentage),
              })
            }}
            endAdornment={'%'}
          />
        }
        viewDivider={false}
        py={0}
      />
      <ListItemDetail
        title="Comissão Global"
        value={
          <OutlinedInput
            fullWidth
            size="small"
            id="global-commission-label"
            value={maskPercentage(commission.globalCommissionPercentage)}
            onChange={(e) => {
              const globalCommissionPercentage = maskPercentage(e.target.value)
                .replaceAll('.', '')
                .replaceAll(',', '.')
              setCommissionRule({
                ...commission,
                globalCommissionPercentage: Number(globalCommissionPercentage),
              })
            }}
            endAdornment={'%'}
          />
        }
        viewDivider={false}
        py={0}
      />
      <ListItemDetail
        title="Data do fim da regra"
        value={
          <Stack
            direction="row"
            spacing={2}
            alignContent="center"
            alignItems="center"
          >
            <SwitchRound
              checked={
                commission.closingDate !== undefined &&
                commission?.closingDate !== null
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setCommissionRule({ ...commission, closingDate: new Date() })
                } else {
                  setCommissionRule({ ...commission, closingDate: null })
                }
              }}
            />
            <Fade
              in={
                commission.closingDate !== undefined &&
                commission.closingDate !== null
              }
              mountOnEnter
              unmountOnExit
            >
              <Stack direction="row">
                <BasicDatePicker
                  value={commission.closingDate}
                  setValue={(e) =>
                    setCommissionRule({ ...commission, closingDate: e })
                  }
                />
              </Stack>
            </Fade>
          </Stack>
        }
        viewDivider={false}
        py={0}
      />
      <ListItemDetail
        title="Reiniciar comissões"
        value={
          <Stack
            direction="row"
            spacing={2}
            alignContent="center"
            alignItems="center"
          >
            <SwitchRound
              checked={commission.restartCommission}
              onChange={(e) => {
                setCommissionRule({
                  ...commission,
                  restartCommission: e.target.checked,
                })
              }}
            />
            <InfoTooltip title="No dia 1 de cada mês, o sistema irá verificar as regras configuradas e atualizar a comissão do afiliado para os produtos que se enquadram nessas condições. Essa atualização será feita para refletir a comissão base do produto estipulada na regra." />
          </Stack>
        }
        viewDivider={false}
        py={0}
      />
    </Stack>
  )
}

export default CommissionRuleData
