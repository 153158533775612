import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import {
  TAffiliated,
  TAffiliatedCommissionType,
} from '../../../../../core/types/Affiliated'
import { useAffiliationsCommissions } from '../../../../../core/hooks/useAffiliationsCommissions'
import { dateString } from '../../../../../core/functions/dateTime'
import InfoTooltip from '../../../../../components/Tooltip/InfoTooltip'
import { TCommissionRuleType } from '../../../../../core/types/CommissionRule'

export interface IMyAffiliationsLinksProp {
  affiliated?: TAffiliated
}

const MyAffiliationsCommissions = ({
  affiliated,
}: IMyAffiliationsLinksProp) => {
  const { loading, data } = useAffiliationsCommissions({
    id: affiliated?.id || '',
  })

  return (
    <Box>
      {loading && (
        <Stack
          direction="row"
          alignContent="center"
          alignItems="center"
          sx={{ p: 5 }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && (
        <Grid container columns={16}>
          <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
            <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
              Início
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
            <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
              Fim
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: 'divider' }}>
            <Typography
              variant="subtitle2"
              textAlign="end"
              sx={{ px: 2, py: 1 }}
            >
              Comissão
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
            <Typography
              variant="subtitle2"
              textAlign="end"
              sx={{ px: 2, py: 1 }}
            >
              Geral
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ backgroundColor: 'divider' }}>
            <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
              Tipo
            </Typography>
          </Grid>
          {data?.map((commission) => (
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
            >
              <Grid item xs={3}>
                <Typography variant="body2" sx={{ px: 2, py: 1 }}>
                  {dateString(commission.startDate)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" sx={{ px: 2, py: 1 }}>
                  {commission.endDate === null
                    ? 'Eterno'
                    : dateString(commission.endDate)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  textAlign="end"
                  sx={{ px: 2, py: 1 }}
                >
                  {NumberFunctions.toPercentage(
                    commission.commissionPercentage
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="body2"
                  textAlign="end"
                  sx={{ px: 2, py: 1 }}
                >
                  {NumberFunctions.toPercentage(
                    commission.globalCommissionPercentage
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  sx={{ pl: 2 }}
                >
                  <Typography variant="body2">
                    {commission.commissionTypeDescription}
                  </Typography>
                  <InfoTooltip
                    title={
                      commission.commissionType ===
                      TAffiliatedCommissionType.Targes
                        ? 'A comissão foi reajustada automaticamente devido ao cumprimento da meta estabelecida na regra de comissão.'
                        : commission.commissionType ===
                            TAffiliatedCommissionType.Manual
                          ? 'A comissão foi ajustada manualmente para refletir uma alteração específica definida pelo produtor.'
                          : 'A comissão foi reajustada automaticamente no primeiro dia do mês para alinhar com a configuração padrão de comissão do produto. Para mais informações entrar em contato com o produtor.'
                    }
                  />
                </Stack>
              </Grid>
            </Stack>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default MyAffiliationsCommissions
