import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import { dateString, dateTimeString } from '../../../core/functions/dateTime'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'
import Convertions from '../../../core/functions/convertions'
import { TCommissionRule } from '../../../core/types/CommissionRule'
import NumberFunctions from '../../../core/functions/NumberFunctions'

interface AbandonedItemMobileProps {
  item: TCommissionRule
  index: number
  onEdit: (item: TCommissionRule) => void
}

const CommissionRuleItemMobile = ({
  item,
  index,
  onEdit,
}: AbandonedItemMobileProps) => {
  return (
    <Box key={`commission${index}`} width="100%" sx={{ mb: 1, px: 2 }}>
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="14px"
                    lineHeight="18px"
                    color="rgba(56, 57, 59, 1)"
                  >
                    {item.identifier}
                  </Typography>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(99, 126, 239, 1)"
                  >
                    {dateTimeString(item.createdDate)}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Chip
                  label={
                    <Typography fontSize={12} lineHeight={16} fontWeight={500}>
                      {Convertions.commissionRuleTypeToString(item.ruleType)}
                    </Typography>
                  }
                  size="small"
                  sx={{
                    backgroundColor: '#FBF3E2',
                    color: '#887100',
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Data do fim:
              </Typography>
              <Stack direction={'row'} spacing={1}>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={400}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(28, 82, 189, 1)"
                >
                  {item.closingDate ? dateString(item.closingDate) : 'Eterno'}
                </Typography>
              </Stack>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Comissão:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {NumberFunctions.toPercentage(item.commissionPercentage)}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Comissão Global:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {NumberFunctions.toPercentage(item.globalCommissionPercentage)}
              </Typography>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ mt: 1 }}
            >
              <Button
                size="small"
                onClick={(event) => {
                  event.stopPropagation()
                  onEdit(item)
                }}
                startIcon={
                  <img src="/assets/icons/actions/editWhite.svg" alt="edit" />
                }
                sx={{
                  backgroundColor: 'rgba(56, 57, 59, 1)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(56, 57, 59, 0.9)',
                  },
                  width: '90px',
                  height: '24px',

                  borderRadius: '4px',
                  padding: '2px 8px 2px 4px',
                  gap: '8px',
                  textTransform: 'none',
                }}
              >
                <Typography
                  sx={{
                    width: '61px',
                    height: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Editar
                </Typography>
              </Button>
            </Stack>
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </Box>
  )
}

export default CommissionRuleItemMobile
